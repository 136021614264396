import {FunctionComponent, useEffect, useState} from "react";
import { withTranslation, WithTranslation } from 'react-i18next';
import './InfoBox.scss';
import {InfoBoxType} from "../../types/InfoBoxType";
import {fetchAPI} from "../../services/fetchAPI";
import i18n from 'i18next';

const InfoBox: FunctionComponent<WithTranslation & { langChange: string }> = ({ langChange }) => {

    const [infoBoxData, setInfoBoxData] = useState<InfoBoxType | undefined>(undefined);

    useEffect(() => {
        fetchAPI("/urgent")
            .then((result) => {
                if (Array.isArray(result) && result.length === 0) {
                    setInfoBoxData(undefined);
                } else {
                    setInfoBoxData(result as InfoBoxType);
                    result.lang = i18n.language;
                }

            })
            .catch((err) => { return console.log(err); });
    }, [langChange]);

    if (!infoBoxData) {
        return null;
    }

    return (
        <section className="info">
            <div data-container>
                {infoBoxData.title && <h2>{infoBoxData.title}</h2>}

                {infoBoxData.message &&
                    <div dangerouslySetInnerHTML={{__html: infoBoxData.message}}></div>
                }
            </div>
        </section>
    )
}

/**
 *  ------------------------------------
 *  Exports
 *  ------------------------------------
 */
export default withTranslation(['infobox'])(InfoBox);
